import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Container, ListGroup, Modal } from "react-bootstrap";
import api from "../api";
import NotyfContext from "../contexts/NotyfContext";
import useAuth from "../hooks/useAuth";
import { DebounceInput } from "react-debounce-input";

const AssetHasTag = (props) => {

  const [showModal, setShow] = useState(false);
  const [showDeleteModal, setShowDelete] = useState(false);
  const [currentTag, setTag] = useState('');
  const notify = useContext(NotyfContext);
  const { user } = useAuth();
  const [options, setOption]= useState([]);

  useEffect(() => {
    if(!showModal) return;
    api.get(`hashtags?page=1&limit=10`).then(res => {
      setOption(res.data);
    });
    setTag('');
  }, [showModal])

  const onTextChange = (e, isInput) => {
    setTag(e);
    if(isInput) {
      api.get(`hashtags?page=1&limit=10&filter=${e}`).then(res => {
        setOption(res.data);
      });
    }
  }
  const addTag = () => {
    api.post(`${props.apiName}/${props.id}/hashtag/${currentTag}`).then(() => {
      notify.open({
        type: 'success',
        message: 'Changes Saved'
      })
      props.handleUpdateData();
    })
    setShow(false);
  }
  const showRemoveModal = (value) => {
    if ((props.apiName === 'zones' && user?.editZones) || (props.apiName === 'assets' && user?.editAssets) || (props.apiName === 'people' && user?.editPeople)) {
      setTag(value);
      setShowDelete(true);
    }
  }
  const removeTag = () => {
    api.delete(`assets/${props.id}/hashtag/${currentTag}`).then(() => {
      notify.open({
        type: 'success',
        message: 'Changes Saved'
      });
      props.handleUpdateData();
    })
    setShowDelete(false);
  }
  return (
    <React.Fragment>
      <Card className="mb-0 border-top">
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center card-title">
            <h5 className="mb-0">#Hashtags</h5>
            {((props.apiName === 'zones' && user?.editZones) || (props.apiName === 'assets' && user?.editAssets) || (props.apiName === 'people' && user?.editPeople)) && <Button size="sm" onClick={() => setShow(true)}>#Add</Button>}
          </div>
          {props.hashTags && props.hashTags.map((tag, i) => {
            return (
              <span key={i} onClick={() => showRemoveModal(tag)} className="badge bg-primary me-1 my-1 cursor-pointer">{tag}</span>
            );
          })}
        </Card.Body>
      </Card>
      <Modal show={showModal} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add a Hashtag</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <DebounceInput
              className="hashtag-input text-gray"
              minLength={0}
              debounceTimeout={300}
              value={currentTag}
              onChange={event => (onTextChange(event.target.value, true))}
            />
            <ListGroup className="mt-2">
              {options.map((item, index) => <ListGroup.Item onClick={(event)=>{event.stopPropagation();onTextChange(item.hashstring)}} key={index} className="cursor-pointer list-group-hover">{item.hashstring}</ListGroup.Item>)}
            </ListGroup>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          {<Button onClick={() => addTag()}>Add</Button>}
        </Modal.Footer>
      </Modal>
      <Modal show={showDeleteModal} onHide={() => setShowDelete(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Please Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>{`Would you like to remove the Hashtag '${currentTag}'?`}</span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => removeTag()}>Remove</Button>
          <Button variant={'secondary'}  onClick={() => setShowDelete(false)}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default AssetHasTag;