import React, { useContext, useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import { Button, Card, Modal, Spinner } from "react-bootstrap";
import defaultZone from "../../assets/img/icon/GPS/location.png";
import selectZone from "../../assets/img/icon/GPS/selectLocation.svg";
import greenZone from "../../assets/img/icon/GPS/location_green.svg";
import FloorplanSelector from "./FloorplanSelector";
import api from "../../api";
import { globalConfig } from "../../config";
import ReaderSelector from "./ReaderSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeartbeat, faSignature } from "@fortawesome/free-solid-svg-icons";
import moment from "moment-timezone";
import { getDistance, middle, radiusZoomLevel } from "../../utils/staticMethods";
import axios from "axios";
import DeviceDetail from "../../components/deviceInfo/DeviceDetail";
import useAuth from "../../hooks/useAuth";
import NotyfContext from "../../contexts/NotyfContext";
import { Disc, Layers, Wifi } from "react-feather";
import { faIdCard } from "@fortawesome/free-regular-svg-icons";

let overlay1 = null;
let source1 = null;
const UserReaderMap = () => {
  const [mapInstance, setInstance] = useState();
  const [mapApi, setApi] = useState();
  const [picture, setPicture] = useState(null);
  const [showReader, setShowReader] = useState(false);
  const [showSearch, setShowSearch] = useState(true);
  const [curFloor, setCurFloor] = useState(null);
  const [loadingPlan, setLoadingPlan] = useState(true);
  const [reader, setReader] = useState(null);
  const [readers, setReaders] = useState([]);
  const [showEditModal, setShowEdit] = useState(null);
  const [name, setName] = useState(null);
  const [hasFloorPlan, setHasFloorPlan] = useState(true);
  const notify = useContext(NotyfContext);
  const { user } = useAuth();

  const getMapOptions = (maps) => {
    if (mapInstance && mapApi) return;
    return {
      mapId: "90f87356969d889c",
      fullscreenControl: true,
      draggableCursor: "default",
      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.ROADMAP,
      scaleControl: true,
      heading: 0,
      gestureHandling: "cooperative",
      streetViewControl: true,
      mapTypeControlOptions: {
        position: maps.ControlPosition.LEFT_BOTTOM
      }
    };
  };

  const onSave = () => {
    let obj = JSON.parse(JSON.stringify(showEditModal))
    obj.deviceName = name
    api.put(`readers`, obj)
      .then((res) => {
        notify.open({
          type: 'success',
          message: 'Changes Saved'
        })
        setShowEdit(null)
        setReader(res.data)
        setReaders(pre => {
          let arr = pre.concat([])
          let filter = arr.filter(item => item.id === res.data.id)
          if(filter.length > 0) {
            filter[0].deviceName = res.data.deviceName
          }
          return arr
        })
      });
  }

  useEffect(() => {
    if (mapApi && mapInstance && curFloor) {
      getPci();
      setLoadingPlan(true);
    }
  }, [mapApi, mapInstance, curFloor]);

  const getPci = () => {
    if(source1){
      source1.cancel('request canceled');
    }
    source1 = axios.CancelToken.source();
    api.get(`files/floorplans/${curFloor.id}`, {
      responseType: "arraybuffer",
      cancelToken: source1.token
    }).then(res => {
      let blob = new Blob([res.data], { type: "img/jpeg" });
      let url = (window.URL || window.webkitURL).createObjectURL(blob);
      setPicture(url);
    }).catch(() => setPicture(null));
  };

  useEffect(() => {
    if (picture) initFloorPlan();
  }, [picture]);

  useEffect(() => {
    return() => {
      if(source1) {
        source1.cancel('request canceled');
      }
    }
  }, [])

  const initFloorPlan = () => {
    if (mapApi && mapInstance && picture) {
      mapInstance.setHeading(360 - curFloor?.rotation || 0);
      if (overlay1) {
        overlay1.setMap(null);
        overlay1 = null;
      }
      let northWest = new mapApi.LatLng(curFloor ? (Number(curFloor.northWestLatitude || 51.5072)) : 51.5072, curFloor ? (Number(curFloor.northWestLongitude) || 0.1276) : 0.1276);
      let southEast = new mapApi.LatLng(curFloor ? (Number(curFloor.southEastLatitude || 51.5072)) : 51.5072, curFloor ? (Number(curFloor.southEastLongitude) || 0.1276) : 0.1276);
      overlay1 = new mapApi.OverlayView();
      overlay1.div = null;
      window.overlay = overlay1;
      overlay1.image = picture;
      overlay1.draw = function() {
        const overlayProjection = this.getProjection();
        const se = overlayProjection.fromLatLngToDivPixel(
          southEast
        );
        const nw = overlayProjection.fromLatLngToDivPixel(
          northWest
        );
        if (this.div) {
          this.div.style.left = nw.x + "px";
          this.div.style.top = nw.y + "px";
          this.div.style.width = se.x - nw.x + "px";
          this.div.style.height = se.y - nw.y + "px";
        }
      };
      overlay1.onRemove = function() {
        if (this.div) {
          this.div.parentNode.removeChild(this.div);
        }
      };
      overlay1.onAdd = function() {
        this.div = document.createElement("div");
        this.div.id = "whole-container";
        this.div.style.borderStyle = "none";
        this.div.style.borderWidth = "0px";
        this.div.style.position = "absolute";
        this.div.style.visibility = "visible";

        const img = document.createElement("img");

        img.src = this.image;
        img.style.width = "100%";
        img.style.height = "100%";
        img.style.position = "absolute";
        this.div.appendChild(img);
        const panes = this.getPanes();
        panes.overlayLayer.appendChild(this.div);
      };
      overlay1.setMap(mapInstance);
      let d = getDistance(northWest, southEast);
      let keys = Object.keys(radiusZoomLevel).reverse();
      let value = 1000000;
      let index = 0;
      for (let i = 0; i < keys.length; i++) {
        let v = Math.abs(radiusZoomLevel[keys[i]] - d);
        if (v < value) {
          value = v;
          index = keys[i];
        }
      }
      mapInstance.setCenter(middle(northWest, southEast));
      mapInstance.setZoom(Number(index));
      window.map = mapInstance
      setTimeout(() => setLoadingPlan(false), 500);
    }
  };

  const apiHasLoaded = (map, maps) => {
    if (!map || !maps) return;
    setInstance(map);
    setApi(maps);
    let thePanorama = map.getStreetView();
    maps.event.addListener(thePanorama, "visible_changed", function() {
      if (thePanorama.getVisible()) {
        setShowSearch(false);
      } else {
        setShowSearch(true);
      }
    });
  };


  const onSelect = (data) => {
    if(!data) {
      setPicture(null)
      setLoadingPlan(false)
      mapInstance && overlay1.setMap(null)
    }
    setCurFloor(data);
  };

  const onSelectReader = (data) => {
    setReader(data);
  };

  const cancelOption = () => {
    setShowReader(prevState => {
      return !prevState;
    });
  };

  const getZoneIcon = (item) => {
    if(item.id === reader?.id) return selectZone
    if(item.wirepas) return greenZone
    return defaultZone
  }

  return (
    <div>
      <div>
        <div style={{ width: "100%" }} className="position-relative">
          {reader && <Card className="mb-0 border-bottom">
            <Card.Body className="d-flex align-items-center ">
              <Wifi className="me-2" size={14} />
              <span onClick={() => setShowEdit(reader)} className="text-primary cursor-pointer">{reader.serial}</span>
              {reader.lastReport && <div className="ms-2">
                <Layers className="me-2" size={14} />
                <span className="me-2">{reader?.model}</span>
                <FontAwesomeIcon className="me-2" icon={faHeartbeat} size={"sm"} />
                <span className="me-2">{moment(reader.lastReport).format("DD/MM/YYYY HH:mm:ss")}</span>
                {reader.deviceName && <><FontAwesomeIcon className="me-2" icon={faSignature} size={"sm"} />
                <span className="me-2">{reader.deviceName}</span></>}
              </div>}
              {(!reader.latitude || !reader.longitude) && <div className="ms-2">
                <span className="ms-2 text-danger">Not Yet Configured</span>
              </div>}
            </Card.Body>
          </Card>}
          <div className={`h-100 position-relative ${curFloor ? "hidden-map" : ""}`}>
            <div className={!showSearch ? 'visually-hidden' : ''}>
              <ReaderSelector showMap reader={reader} getAllReaders={(e) => setReaders(e)} onSelect={onSelectReader} showDefault
                                                                                  showReader={showReader} />
            </div>
            {hasFloorPlan && <div className={`${!showSearch ? 'visually-hidden' : ''} reader-floorplan-select`}>
              <FloorplanSelector noResult={() => setHasFloorPlan(false)} showMap showDefault asset={""} cancelOption={cancelOption} onSelect={onSelect} />
            </div>}
            {loadingPlan && <div
              className="position-absolute w-100 h-100 bg-light z-50 d-flex align-items-center justify-content-center">
              <Spinner animation="border" />
            </div>}
            <GoogleMapReact
              options={getMapOptions}
              bootstrapURLKeys={{
                key: globalConfig.googleMapKey,
                libraries: ["places", "geometry", "drawing", "visualization"]
              }}
              style={{ height: "80vh", position: "relative" }}
              center={{
                lat: Number(reader?.latitude) || 51.5,
                lng: Number(reader?.longitude) || -0.11
              }}
              defaultZoom={15}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
            >
              {mapInstance && mapApi && readers.map(item => <GeoMarker key={item.id} onSelect={() => setReader(item)} item={item}
                                                                       lat={item.latitude} lng={item.longitude} selected={item.id === reader?.id}
                                                                       defaultZone={getZoneIcon(item)} />)}
            </GoogleMapReact>
          </div>
        </div>
      </div>
      <Modal show={showEditModal} onHide={() => setShowEdit(null)}>
        <Modal.Header closeButton>
          <Modal.Title>{showEditModal?.serial}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DeviceDetail onChangeName={(e) => setName(e)} data={showEditModal} type="readers" />
        </Modal.Body>
        <Modal.Footer>
          {user?.editReaders && <Button onClick={() => onSave()}>Save</Button>}
          <Button onClick={() => setShowEdit(false)} variant={'secondary'}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const getBackground = (item, defaultZone) => {
  if(defaultZone) return 'bg-primary'
  if(item.wirepas) return 'bg-success'
  return 'bg-danger'
}

const GeoMarker = ({ defaultZone, onSelect, item, selected }) => (
  <div style={{marginLeft: "-15px", marginTop: "-15px"}}>
    <img alt="marker" onClick={() => onSelect()}
         style={{ height: "30px", width: "30px", cursor: "pointer" }}
         src={defaultZone} />
    <div className={`text-black position-absolute marker-position-bottom ps-1 pe-1 radius-3 text-nowrap font-weight-bold ${getBackground(item, selected)}`} style={{fontSize: '14px', transform: 'translate(-50%, 50%)'}}>{item?.serial}</div>
  </div>);

export default UserReaderMap;
