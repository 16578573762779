import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import SelectHeader from "../SelectHeader";
import { DebounceInput } from "react-debounce-input";
import { useSortBy, useTable } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import { Col, Row, Spinner, Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import api from "../../api";
import moment from "moment-timezone";
import { faClock } from "@fortawesome/free-regular-svg-icons";

const columns = [
  {
    Header: "",
    accessor: "start"
  }
];

const TimelineTable = forwardRef(function _Child({
                                                   id,
                                                   curTab,
                                                   duration,
                                                   type = "asset",
                                                   resetDuration,
                                                   emptyText,
                                                   tableLoaded,
                                                   filterText,
                                                   onChangeFilter
                                                 }, ref) {

  const [data, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [total, setTotal] = useState(null);
  const [filter, setFilter] = useState(filterText || "");
  const [init, setInit] = useState(false);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const { getTableProps, getTableBodyProps, headerGroups, rows } = useTable({
    columns,
    data
  }, useSortBy);

  useImperativeHandle(ref, () => ({
    handleSwitchPage
  }));

  useEffect(() => {
    if (id && curTab === "first") {
      if(!init || duration.length === 0) {
        if(filterText !== filter) {
          setFilter(filterText)
        } else {
          initRequest()
        }
      } else if (init) {
        if(filterText !== filter) {
          setFilter(filterText)
        }
      }
    }
  }, [curTab]);

  useEffect(() => {
    if (id && curTab === "first") {
      init && handleSwitchPage(1);
      onChangeFilter(filter)
    }
  }, [filter, limit]);

  const onChangeSelect = (e) => {
    setLimit(e.target.value);
  };

  const filterResults = (e) => {
    setFilter(e);
  };

  const initRequest = () => {
    setLoading(true);
    if (type === "assets" || type === "people") {
      api.get(`${type}/attendances/${id}?page=1&limit=${limit}&filter=${filter}&startDate=${duration[0] ? duration[0].tz("Europe/London").format("YYYY-MM-DD HH:mm") : ''}&endDate=${duration[1] ? duration[1].tz("Europe/London").format("YYYY-MM-DD HH:mm") : ''}`).then(res => {
        const totalHeader = res.headers["x-total-count"];
        setTotal(totalHeader);
        setPageCount(Math.ceil(totalHeader / limit) || 1);
        setResults(res.data || []);
        setLoading(false);
        setInit(true);
        tableLoaded && tableLoaded(totalHeader);
        if (res.data && res.data.length > 0) {
          let end = moment(res.data[0].start).endOf("days");
          let start = moment(res.data[0].start).subtract(30, "day").startOf("days");
          resetDuration && resetDuration(start, end);
        } else {
          resetDuration && resetDuration(moment().subtract(30, "day").startOf("days"), moment().endOf("days"));
        }
      });
    } else if (type === "zone") {
      api.get(`zones/pastcontents/${id}?page=1&limit=${limit}&filter=${filter}&startDate=${duration[0] ? duration[0].tz("Europe/London").format("YYYY-MM-DD HH:mm") : ''}&endDate=${duration[1] ? duration[1].tz("Europe/London").format("YYYY-MM-DD HH:mm") : ''}`).then(res => {
        const totalHeader = res.headers["x-total-count"];
        setTotal(totalHeader);
        setPageCount(Math.ceil(totalHeader / limit) || 1);
        setResults(res.data || []);
        setLoading(false);
        setInit(true);
        tableLoaded && tableLoaded(totalHeader);
        if (res.data && res.data.length > 0) {
          let end = moment(res.data[0].start).endOf("days");
          let start = moment(res.data[0].start).subtract(30, "day").startOf("days");
          resetDuration && resetDuration(start, end);
        } else {
          resetDuration && resetDuration(moment().subtract(30, "day").startOf("days"), moment().endOf("days"));
        }
      });
    } else if (type === "assetsPast" || type === "peoplePast") {
      api.get(`${type === "assetsPast" ? "assets" : "people"}/pastcontents/${id}?page=1&limit=${limit}&filter=${filter}&startDate=${duration[0] ? duration[0].tz("Europe/London").format("YYYY-MM-DD HH:mm") : ''}&endDate=${duration[1] ? duration[1].tz("Europe/London").format("YYYY-MM-DD HH:mm") : ''}`).then(res => {
        const totalHeader = res.headers["x-total-count"];
        setTotal(totalHeader);
        setPageCount(Math.ceil(totalHeader / limit) || 1);
        setResults(res.data || []);
        setLoading(false);
        setInit(true);
        tableLoaded && tableLoaded(totalHeader);
        if (res.data && res.data.length > 0) {
          let end = moment(res.data[0].start).endOf("days");
          let start = moment(res.data[0].start).subtract(30, "day").startOf("days");
          resetDuration && resetDuration(start, end);
        } else {
          resetDuration && resetDuration(moment().subtract(30, "day").startOf("days"), moment().endOf("days"));
        }
      });
    }
  };

  const handlePageClick = (data) => {
    handleSwitchPage(data.selected + 1);
  };

  const getDuration = (time) => {
    if(time === 0) return 'Unknown'
    let currentTime = moment.duration(time, "seconds");
    if (currentTime.days() > 0) {
      let hours = currentTime.days() * 24 + currentTime.hours();
      let min = currentTime.minutes();
      return hours + ":" + min;
    }
    return moment({
      h: currentTime.hours(),
      m: currentTime.minutes(),
      s: currentTime.seconds()
    }).format("HH:mm");
  };

  const handleSwitchPage = (cur) => {
    setCurrentPage(cur);
    setLoading(true);
    if (type === "assets" || type === "people") {
      api.get(`${type}/attendances/${id}?page=${cur}&limit=${limit}&filter=${filter}&startDate=${duration[0].tz("Europe/London").format("YYYY-MM-DD HH:mm")}&endDate=${duration[1].tz("Europe/London").format("YYYY-MM-DD HH:mm")}`)
        .then(res => {
          const totalHeader = res.headers["x-total-count"];
          setTotal(totalHeader);
          setPageCount(Math.ceil(totalHeader / limit) || 1);
          setResults(res.data || []);
          setLoading(false);
          tableLoaded && tableLoaded(totalHeader);
        }).catch(() => {
        setTotal(0);
        setPageCount(1);
        tableLoaded && tableLoaded(0);
        setLoading(false);
        setResults([]);
      });
    } else if (type === "zone") {
      api.get(`zones/pastcontents/${id}?page=${cur}&limit=${limit}&filter=${filter}&startDate=${duration[0].tz("Europe/London").format("YYYY-MM-DD HH:mm")}&endDate=${duration[1].tz("Europe/London").format("YYYY-MM-DD HH:mm")}`)
        .then(res => {
          const totalHeader = res.headers["x-total-count"];
          setTotal(totalHeader);
          setPageCount(Math.ceil(totalHeader / limit));
          setResults(res.data || []);
          setLoading(false);
          tableLoaded && tableLoaded(totalHeader);
        }).catch(() => {
        setTotal(0);
        setPageCount(1);
        tableLoaded && tableLoaded(0);
        setLoading(false);
        setResults([]);
      });
    } else if (type === "assetsPast" || type === "peoplePast") {
      api.get(`${type === "assetsPast" ? "assets" : "people"}/pastcontents/${id}?page=1&limit=${limit}&filter=${filter}&startDate=${duration[0].tz("Europe/London").format("YYYY-MM-DD HH:mm")}&endDate=${duration[1].tz("Europe/London").format("YYYY-MM-DD HH:mm")}`).then(res => {
        const totalHeader = res.headers["x-total-count"];
        setTotal(totalHeader);
        setPageCount(Math.ceil(totalHeader / limit));
        setResults(res.data || []);
        setLoading(false);
        setInit(true);
        tableLoaded && tableLoaded(totalHeader);
      }).catch(() => {
        setTotal(0);
        setPageCount(1);
        tableLoaded && tableLoaded(0);
        setLoading(false);
        setResults([]);
      });
    }
    setInit(true);
  };

  const isSameDay = (start, end) => {
    if(end.indexOf('0001-01-01') > -1) return true
    return start.split("T")[0] === end.split("T")[0];
  };

  return (
    <React.Fragment>
      {(data.length > 0 || (data.length === 0 && filter !== "") || loading) && <>
        <Row className="d-flex justify-content-between w-100">
          <Col sm={12} md={6} className="d-flex align-items-center dataTables_length">
            <div className="d-flex align-items-center page-select-container">
              <span>Show</span>
              <SelectHeader limit={limit} onChange={onChangeSelect} />
              <span>entries</span>
            </div>
          </Col>
          <Col sm={12} md={6}
               className="d-flex dataTables_filter justify-content-lg-end justify-content-sm-center align-items-center">
            <span>Search:</span>
            <DebounceInput
              className="table-search debounceInput-search text-gray"
              minLength={0}
              debounceTimeout={500}
              value={filter}
              onChange={event => (filterResults(event.target.value))}
            />
          </Col>
        </Row>
        {loading ? <Spinner animation="border" /> : (
          data.length > 0 ? <>
            <Table responsive="sm" striped {...getTableProps()}>
              <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props
                    <th {...column.getHeaderProps(column.getSortByToggleProps())} className={column.className}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
              </thead>
              <tbody {...getTableBodyProps()}>
              {rows.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="ps-7">
                      {(type === "assets" || type === "people") && <div className="mb-1">
                        <h3 className="mb-1">
                          <NavLink className="link-primary" to={{
                            pathname: `/${item.original.parentType === "asset" ? "assets" : "zones"}/edit`,
                            search: `?id=${item.original.parentId}`
                          }}>{item.original.parentName}</NavLink>
                          <span
                            className="ms-1">{isSameDay(item.original.start, item.original.end) ? moment(item.original.start).format("DD/MM/YY") : `${moment(item.original.start).format("DD/MM/YY")} - ${moment(item.original.end).format("DD/MM/YY")}`}</span>
                        </h3>
                        {item.original.parentHashTags && item.original.parentHashTags.map(item1 => <div key={item1}
                                                                                                          className="ms-1 badge bg-primary me-1">{item1}</div>)}
                      </div>}
                      {type === "zone" && <div className="mb-1"><h3 className="mb-1">
                        <NavLink className="link-primary" to={{
                          pathname: `/${item.original.childType === "person" ? "people" : `${item.original.childType}s`}/edit`,
                          search: `?id=${item.original.childId}`
                        }}>{item.original.childName}</NavLink>
                        <span
                          className="ms-1">{isSameDay(item.original.start, item.original.end) ? moment(item.original.start).format("DD/MM/YY") : `${moment(item.original.start).format("DD/MM/YY")} - ${moment(item.original.end).format("DD/MM/YY")}`}</span>
                      </h3>
                        {item.original.childHashTags && item.original.childHashTags.map(item1 => <div key={item1}
                                                                                                        className="ms-1 badge bg-primary me-1">{item1}</div>)}
                      </div>}
                      {type === "assetsPast" && <div className="mb-1"><h3 className="mb-1">
                        <NavLink className="link-primary" to={{
                          pathname: `/${item.original.childType === "person" ? "people" : `${item.original.childType}`}/edit`,
                          search: `?id=${item.original.childId}`
                        }}>{item.original.childName}</NavLink>
                        <span className="ms-1">{moment(item.original.start).format("DD/MM/YY")}</span>
                      </h3>
                        {item.original.childHashTags && item.original.childHashTags.map(item1 => <div key={item1}
                                                                                                        className="ms-1 badge bg-primary me-1">{item1}</div>)}
                      </div>}
                      <div>
                        <FontAwesomeIcon icon={faSignInAlt} fixedWidth />
                        <span className="ms-1">Arrived:</span>
                        <span>&nbsp; {moment(item.original.start).format("HH:mm")}</span>
                      </div>
                      <div>
                        <FontAwesomeIcon icon={faSignOutAlt} fixedWidth />
                        <span className="ms-1">Departed:</span>
                        <span>&nbsp; {item.original.end.indexOf('0001-01-01') > -1 ? 'Missed' : moment(item.original.end).format("HH:mm") }</span>
                      </div>
                      <div>
                        <FontAwesomeIcon icon={faClock} fixedWidth />
                        <span className="ms-1">Duration:</span>
                        <span>&nbsp; {getDuration(item.original.duration)}</span>
                      </div>
                    </td>
                  </tr>
                );
              })}
              </tbody>
            </Table>
            <Row className="d-flex align-items-center">
              {data.length > 0 &&
              <Col md={5} sm={12}
                   className="dataTables_info">{`Showing ${(currentPage - 1) * limit + 1} to ${(currentPage - 1) * limit + data.length} of ${total} entries`}</Col>}
              <Col md={7} sm={12} className="dataTables_paginate d-md-flex justify-content-md-end">
                <ReactPaginate
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  forcePage={currentPage - 1}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination m-0"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </Col>
            </Row>
          </> : <div className="w-100 p-3 text-center mt-3 mb-3 bg-light">{"No data available"}</div>)}
      </>}
      {!loading && filter === "" && data.length === 0 && <div>
        <div>{(type !== "assetsPast" && type !== "peoplePast") ? emptyText : "No data available"}</div>
      </div>}
    </React.Fragment>
  );
});
export default TimelineTable;