import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Col, Container, Nav, Row, Tab } from "react-bootstrap";
import api from "../../api";

import LastLocation from "../../components/LastLocation";
import AssetDetails from "../../components/AssetDetails";
import LastAttendance from "../../components/LastAttendance";
import AssetHasTag from "../../components/AssetHasTag";
import OverviewTab from "../../components/detail/OverviewTab";
import DeviceTracker from "../../components/detail/DeviceTracker";
import DeviceTag from "../../components/detail/DeviceTag";
import Notes from "../../components/detail/Notes";
import { AlertTriangle } from "react-feather";
import CommonTable from "../../components/tables/CommonTable";
import Diagnostics from "../../components/detail/Diagnostics";
import FavouriteModal from "../../components/favourite/FavouriteModal";
import Calendar from "../../components/calendar/Calendar";
import useAuth from "../../hooks/useAuth";
import NotyfContext from "../../contexts/NotyfContext";
import UploadImgModal from "../../components/UploadImgModal";
import AddExistingModal from "../rules/AddExistingModal";
import QRCodeComponent from "../../components/detail/QRCodeComponent";
import { blobToFile, compressPic, dataURLtoBlob, filterStorage } from "../../utils/staticMethods";
import RootTopBar from "../../components/navbar/RootTopBar";
import axios from "axios";
import DeviceCharger from "../../components/detail/DeviceCharger";
import ChargerInfo from "../../components/ChargerInfo";
import SignalMonitoring from "../../components/SignalMonitoring";
import WeatherBar from "../../components/WeatherBar";
import StatisticsTab from "../../components/detail/StatisticsTab";

const columns = [
  {
    Header: "Name",
    accessor: "ruleName",
    type: "link",
    link: "/rules/edit",
    stateId: "id"
  },
  {
    Header: "Type",
    accessor: "type"
  },
  {
    Header: "Status",
    accessor: "status",
    type: "ruleStatus"
  }
];
let interval;
let source1 = null;
const AssetsEdit = ({id, direct}) => {
  const location = useLocation();
  const [asset, setAsset] = useState({});
  const [loadLastPosition, setLoadLastPosition] = useState(0);
  const [loadAssetDetails, setLoadAssetDetails] = useState(0);
  const [trackers, setTrackers] = useState(null);
  const [charger, setCharger] = useState(null);
  const [tags, setTags] = useState(null);
  const [tab, setTab] = useState("overview");
  const [attendance, setAttendance] = useState(null);
  const [picture, setPicture] = useState(null);
  const [showUpload, setShowUpload] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);
  const [showRuleModal, setShowRuleModal] = useState(false);
  const [widgets, setWidgets] = useState(null);
  const ruleRef = useRef();
  const notify = useContext(NotyfContext);
  const { user } = useAuth();
  const navigate = useNavigate()
  const isUnmount = useRef(false);

  useEffect(() => {
    window.scrollTo({ top:0, left:0, behavior: "instant"})
    getAsset();
    getWidgets();
    getPci();
    if (direct) {
      setTab(direct);
    }else {
      setTab('overview')
    }
    interval && clearInterval(interval)
    interval = setInterval(() => {
      getAsset();
    }, 5000);
  }, [id]);

  useEffect(() => {
    return () => {
      clearInterval(interval)
      isUnmount.current = true
      if(source1) {
        source1.cancel('request canceled');
      }
    };
  }, []);

  const
    getPci = () => {
    api.get(`files/assets/${id}/profilepic`, {
      responseType: "arraybuffer"
    }).then(res => {
      if(isUnmount.current) return
      let blob = new Blob([res.data], { type: "img/jpeg" });
      let url = (window.URL || window.webkitURL).createObjectURL(blob);
      setPicture(url);
    }).catch(err => {
      if(isUnmount.current) return
      setPicture(null)
    });
  };

  const onDelete = () => {
    api.delete(`files/assets/${id}/profilepic`).then(() => {
      notify.open({
        type: "success",
        message: "Changes Saved"
      });
      setPicture(null);
      setShowUpload(false);
    });
  };

  const getChargerReady = (charger) => {
    setCharger(charger)
  }

  const onUpload = () => {
    if (!uploadFile || uploadFile.length === 0) return;
    let reader = new FileReader();
    let base;
    reader.onload = function (evt) {
      let replaceSrc = evt.target.result;
      base = compressPic(replaceSrc, base => {
        let blob = dataURLtoBlob(base)
        let miniFile = blobToFile(blob, 'new' + uploadFile[0].name, uploadFile[0].type)
        console.log((miniFile.size / 1024) + 'KB')
        let data = new FormData();
        data.append("uploadedFile", miniFile);
        let config = {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        };
        api.post(`files/assets/${id}/profilepic`, data, config).then(() => {
          notify.open({
            type: "success",
            message: "Changes Saved"
          });
          setShowUpload(false);
          getPci();
        }).catch(err => {
          notify.open({
            type: "error",
            message: err
          });
        });
      });
    };
    reader.readAsDataURL(uploadFile[0]);
  };

  useEffect(() => {
    if(asset.id) {
      let obj = {
        link: location.pathname,
        search: location.search,
        name: `${asset.compoundName} ${asset.reference ? `(${asset.reference})` : ""}`,
        icon: asset.icon,
        id: asset.id
      }
      filterStorage(obj)
    }
  }, [asset])

  const getAsset = () => {
    if(source1){
      source1.cancel('request canceled');
    }
    source1 = axios.CancelToken.source();
    api.get(`assets/` + id, {
      cancelToken: source1.token
    })
      .then(res => {
        if(isUnmount.current) return
        setAsset(res.data);
        if (res.data.lastPosition != null) {
          setLoadLastPosition(1);
        }
        setLoadAssetDetails(1);
      });
  };

  const getWidgets = () => {
    api.get(`assets/widgets/${id}`).then(r => {
      if(r.data && r.data.length > 0) {
        setWidgets(r.data.sort(sortArray));
      }
    })
  }

  const sortArray = (a, b) => {
    return a?.position - b?.position
  }

  const onGetAttendance = (list) => {
    setAttendance(list);
  };

  const getTrackerReady = (list) => {
    setTrackers(list);
  };

  const getTagReady = (list) => {
    setTags(list);
  };

  const handleUpdateData = () => {
    getAsset();
  };
  const onTabChange = (e) => {
    setTab(e);
  };

  return (
    <React.Fragment>
      <Helmet defer={false} title={asset.compoundName || "Asset"}>
      </Helmet>
      <RootTopBar data={asset} />
      <Container fluid className="p-0">
        {asset.compoundName &&
        <h1 className="h3 mb-3">{`${asset.compoundName} ${asset.reference ? `(${asset.reference})` : ""}`}
          <FavouriteModal id={asset.id} type="assets" /></h1>}
        <Row>
          <Col md="4" xl="3">
            {picture && <div className="p-3 bg-white border-bottom">
              <img alt="profile" className="w-100 cursor-pointer" src={picture} onClick={() => {
                if(user?.editAssets && user?.role !== 'Root') {
                  setShowUpload(true)
                }
              }} />
            </div>}
            {loadLastPosition ? (<LastLocation profile={asset} />) : null}
            {trackers && trackers.length > 0 && trackers[0].showSignalMonitoring && <SignalMonitoring asset={asset} tracker={trackers[0]} />}
            {charger && <ChargerInfo id={charger.id} />}
            {asset.lastAttendance ? (<LastAttendance profile={asset} />) : null}
            {loadLastPosition ? (<WeatherBar data={asset.lastPosition} />) : null}
            <AssetHasTag apiName="assets" handleUpdateData={handleUpdateData} id={id} hashTags={asset.hashTags} />
            {!picture && user?.editAssets && user?.role !== 'Root' &&  <div className="border-top p-3 bg-white">
              <span className="text-primary cursor-pointer"
                    onClick={() => setShowUpload(true)}>Add Profile Picture</span>
            </div>}
          </Col>
          <Col md="8" xl="9" className="mt-3 mt-sm-0">
            <div className="tab">
              <Tab.Container id="left-tabs-example" activeKey={tab} onSelect={(e) => onTabChange(e)}>
                <Nav variant="tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="overview">Overview</Nav.Link>
                  </Nav.Item>
                  {widgets && asset && <Nav.Item>
                    <Nav.Link eventKey="statistics">Statistics</Nav.Link>
                  </Nav.Item>}
                  <Nav.Item>
                    <Nav.Link eventKey="details">Details</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="devices">Devices</Nav.Link>
                  </Nav.Item>
                  {user?.role !== 'Root' && <Nav.Item>
                    <Nav.Link eventKey="calendar">Calendar</Nav.Link>
                  </Nav.Item>}
                  <Nav.Item>
                    <Nav.Link eventKey="diagnostics">Diagnostics</Nav.Link>
                  </Nav.Item>
                  {user?.role !== 'Root' && <Nav.Item>
                    <Nav.Link eventKey="rules">Rules</Nav.Link>
                  </Nav.Item>}
                  {user?.viewNotes && user?.role !== 'Root' && <Nav.Item>
                    <Nav.Link eventKey="notes">Notes</Nav.Link>
                  </Nav.Item>}
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="overview">
                    <OverviewTab tab={tab} apiName={"assets"} data={asset} onGetAttendance={onGetAttendance} trackers={trackers}
                                 id={id} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="statistics">
                    <StatisticsTab tab={tab} id={id} data={widgets} asset={asset} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="details">
                    {loadAssetDetails ? (
                      <AssetDetails apiName="assets" type="assets" asset={asset} setAsset={setAsset} />) : null}
                  </Tab.Pane>
                  <Tab.Pane eventKey="devices">
                    <DeviceTracker id={id} getTrackerReady={getTrackerReady} type="assets" data={asset} />
                    <DeviceTag getTagReady={getTagReady} id={id} type="assets" data={asset} />
                    {(user?.showSolarMonitoring || user?.role === 'Root')  && <DeviceCharger getChargerReady={getChargerReady} id={id} type="assets" data={asset}  />}
                    <QRCodeComponent value={id} type={'Asset'} icon={asset.icon} name={asset.compoundName} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="calendar">
                    {asset.id && <Calendar attendance={attendance} id={asset.id} type={'assets'} data={asset} tab={tab} />}
                  </Tab.Pane>
                  <Tab.Pane eventKey="diagnostics">
                    {asset.id && <Diagnostics tags={tags} root trackers={trackers} id={id} type={1} />}
                  </Tab.Pane>
                  <Tab.Pane eventKey="rules">
                    <div className="d-flex justify-content-between mb-3">
                      <h4 className="text-nowrap me-1"><AlertTriangle size={18} /> Rules</h4>
                      <div>
                        {user?.editAssets && <Button onClick={() => setShowRuleModal(true)} className="me-1" variant="success">Add to Existing</Button>}
                        {user?.editAssets && user?.createRules &&<Button onClick={() => navigate(`/rules/create?link=assets&linkId=${id}`)}>Create New</Button>}
                      </div>
                    </div>
                    <CommonTable ref={ruleRef} apiName="rules" parentId={id} columns={columns} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="notes">
                    <Notes id={id} type="assets" />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Col>
        </Row>
      </Container>
      <AddExistingModal onHide={() => setShowRuleModal(false)} show={showRuleModal}  apiName={'assets'} dataId={id} onUpdatedRules={() => {ruleRef.current.updateTable(1); setShowRuleModal(false)}}/>
      <UploadImgModal setUploadFile={(e) => setUploadFile(e)} showUpload={showUpload} onDelete={onDelete}
                      onHide={() => setShowUpload(false)} picture={picture} onUpload={onUpload} />
    </React.Fragment>
  );
};

export default AssetsEdit;
