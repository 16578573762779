import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";

import GoogleMapReact from "google-map-react";
import { imageSet } from "../../icons/AssetIcon/IconList";
import defaultImg from "../../../assets/img/icon/Aircraft/Airliner_Black.png";
import SearchBox from "./SearchBox";
import api from "../../../api";
import axios from "axios";
import circlePng from "../../../assets/img/clusterImg/m1.png";
import circle from "../../../assets/img/clusterImg/m1.png";
import { GridAlgorithm, MarkerClusterer } from "@googlemaps/markerclusterer";
import { getDistance, getUrl, radiusZoomLevel, resArr, updateMarker } from "../../../utils/staticMethods";
import FloorplanSelector from "../../readers/FloorplanSelector";
import defaultZone from "../../../assets/img/icon/GPS/location.png";
import { globalConfig } from "../../../config";
import useAuth from "../../../hooks/useAuth";
import MarkerModal from "../../../components/mapComponents/MarkerModal";
import NearByAssets from "../../../components/mapComponents/NearByAssets";
import InfoWindow from "../../../components/mapComponents/InfoWindow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowMaximize } from "@fortawesome/free-solid-svg-icons";

let overlay1 = null;
let timer = null;
let markerArr = [];
let markerCluster = null;
let intervalNearby = null;
let zoomTimer = null;
let mapType = "roadmap";
let source = null;
let tower1 = null;
let radiusCircle = null;
const thingsArr = { asset: "assets", person: "people", zone: "zones" };

// asset/person detail screen overview tab, map details

const Markers = React.memo(({
                              onCancelSearch,
                              findAddress,
                              following,
                              tower,
                              id,
                              data,
                              tab,
                              hideAction,
                              hideOther
                            }) => {
  const [overlay, setOverlay] = useState(false);
  const [address, setAddress] = useState("");
  const [noData, setNoData] = useState(false);
  const [mapInstance, setInstance] = useState();
  const [mapApi, setApi] = useState();
  const [curFloor, setCurFloor] = useState();
  const [loadingPlan, setLoadingPlan] = useState(true);
  const [picture, setPicture] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(18);
  const [infoWindow, setInfoWindow] = useState(null);
  const limit = 450;
  const [center, setCenter] = useState(null);
  const [current, setCurrent] = useState(null);
  const [firstLoad, setFirstLoad] = useState(false);
  const [filter, setFilter] = useState("");
  const infoRef = useRef(infoWindow);
  const centerRef = useRef(center);
  const currentRef = useRef(current);
  const zoomLevelRef = useRef(zoomLevel);
  const searchBox = useRef();
  const hideOthers = useRef(hideOther);
  const filterRef = useRef(filter);
  const { user } = useAuth();
  const curFloorRef = useRef(curFloor);
  const [infoItem, setInfoItem] = useState([]);
  const infoItemRef = useRef(infoItem);
  const [nearByData, setNearByData] = useState([]);
  const [tableData, setTableData] = useState(null);


  useEffect(() => {
    if (user && !user.showFloorPlans) {
      setLoadingPlan(false);
      setFirstLoad(true);
    }
  }, [user]);

  useEffect(() => {
    hideOthers.current = hideOther;
    if (hideOthers.current) {
      setInfoItem([])
      markerArr.forEach(item => {
        if (item.type !== "zone" && item.type !== "zones") {
          item.setMap(null);
        }
      });
      if (markerCluster) {
        markerCluster.clearMarkers();
      }
    } else {
      markerArr.forEach(item => {
        if (item.type !== "zone" && item.type !== "zones") {
          item.setMap(mapInstance);
          markerCluster && markerCluster.addMarker(item);
        }
      });
    }
  }, [hideOther]);

  useEffect(() => {
    infoItemRef.current = infoItem
  }, [infoItem])

  const getMapOptions = (maps) => {
    if (mapInstance && mapApi) return;
    return {
      mapId: "90f87356969d889c",
      fullscreenControl: true,
      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.ROADMAP,
      scaleControl: true,
      heading: 0,
      gestureHandling: "cooperative",
      streetViewControl: true,
      mapTypeControlOptions: {
        position: maps.ControlPosition.LEFT_BOTTOM
      }
    };
  };

  useEffect(() => {
    setInfoItem([]);
    setNoData(false);
    setCurFloor(null);
    curFloorRef.current = null;
    mapInstance && mapInstance.setZoom(18)
    if(user && !user.showFloorPlans) {
      setFirstLoad(true)
    }
    mapInstance && center && mapInstance.panTo(center)
  }, [id, mapInstance]);

  useEffect(() => {
    setCenter({ lat: Number(data?.lastPosition?.latitude), lng: Number(data?.lastPosition?.longitude) });
    centerRef.current = { lat: Number(data?.lastPosition?.latitude), lng: Number(data?.lastPosition?.longitude) };
    return () => {
      clearTimeout(timer);
      timer = null;
      markerArr.forEach(item => {
        item.setMap && item.setMap(null)
      })
      markerArr = [];
      markerCluster = null;
      clearInterval(intervalNearby);
      intervalNearby = null;
      clearTimeout(zoomTimer);
      zoomTimer = null;
      tower1 = null;
      radiusCircle = null;
    };
  }, []);

  useEffect(() => {
    if (findAddress) {
      getReverseGeocodingData(current.lastPosition?.latitude, current.lastPosition?.longitude);
    }
  }, [findAddress]);

  const getReverseGeocodingData = (lat, lng) => {
    let latlng = new mapApi.LatLng(lat, lng);
    let geocoder = new mapApi.Geocoder();
    geocoder.geocode({ "latLng": latlng }, (results, status) => {
      if (status !== mapApi.GeocoderStatus.OK) {
        alert(status);
      }
      // This is checking to see if the Geoeode Status is OK before proceeding
      if (status === mapApi.GeocoderStatus.OK) {
        setAddress((results[0].formatted_address));
      }
    });
  };

  useEffect(() => {
    if (mapInstance && mapApi && tab === "overview") {
      if(following && (currentRef.current.lastPosition.latitude !== data.lastPosition.latitude || currentRef.current.lastPosition.longitude !== data.lastPosition.longitude)) {
        mapInstance.panTo({ lat: Number(data?.lastPosition?.latitude), lng: Number(data?.lastPosition?.longitude) });
        setCenter({ lat: Number(data?.lastPosition?.latitude), lng: Number(data?.lastPosition?.longitude) });
        centerRef.current = { lat: Number(data?.lastPosition?.latitude), lng: Number(data?.lastPosition?.longitude) };
      }
      if (!firstLoad) mapInstance.setZoom(18);
    }
    setCurrent(data);
    currentRef.current = data;
  }, [following, data, mapApi]);

  useEffect(() => {
    if (mapApi && mapInstance && tab === "overview") {
      setInfoWindow(new mapApi.InfoWindow({
        content: ""
      }));
      infoRef.current = new mapApi.InfoWindow({
        content: ""
      });
      if (firstLoad && current && center) {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
          if (intervalNearby) {
            clearInterval(intervalNearby);
            intervalNearby = null;
          }
          zoomLevel !== mapInstance.getZoom() && mapInstance.setZoom(zoomLevelRef.current);
          getNearBy();
          intervalNearby = setInterval(() => {
            getNearBy();
          }, 5000);
        }, 700);
      }
    }
  }, [zoomLevel, center, mapApi, mapInstance, firstLoad]);

  useEffect(() => {
    if (picture) initFloorPlan();
  }, [picture]);

  const initFloorPlan = () => {
    // create floorplan if it has
    if (mapApi && mapInstance && picture) {
      mapInstance.setHeading(360 - curFloor?.rotation || 0);
      if (overlay1) {
        overlay1.setMap(null);
        overlay1 = null;
      }
      let northWest = new mapApi.LatLng(curFloor ? (Number(curFloor.northWestLatitude || 51.5072)) : 51.5072, curFloor ? (Number(curFloor.northWestLongitude) || 0.1276) : 0.1276);
      let southEast = new mapApi.LatLng(curFloor ? (Number(curFloor.southEastLatitude || 51.5072)) : 51.5072, curFloor ? (Number(curFloor.southEastLongitude) || 0.1276) : 0.1276);
      overlay1 = new mapApi.OverlayView();
      overlay1.div = null;
      overlay1.image = picture;
      overlay1.draw = function() {
        const overlayProjection = this.getProjection();
        const se = overlayProjection.fromLatLngToDivPixel(
          southEast
        );
        const nw = overlayProjection.fromLatLngToDivPixel(
          northWest
        );
        if (this.div) {
          this.div.style.left = nw.x + "px";
          this.div.style.top = nw.y + "px";
          this.div.style.width = se.x - nw.x + "px";
          this.div.style.height = se.y - nw.y + "px";
        }
      };
      overlay1.onRemove = function() {
        if (this.div) {
          this.div.parentNode.removeChild(this.div);
        }
      };
      overlay1.onAdd = function() {
        this.div = document.createElement("div");
        this.div.id = "whole-container";
        this.div.style.borderStyle = "none";
        this.div.style.borderWidth = "0px";
        this.div.style.position = "absolute";
        this.div.style.visibility = "visible";

        const img = document.createElement("img");

        img.src = this.image;
        img.style.width = "100%";
        img.style.height = "100%";
        img.style.position = "absolute";
        this.div.appendChild(img);
        const panes = this.getPanes();
        panes.overlayLayer.appendChild(this.div);
      };
      overlay1.setMap(mapInstance);
      let d = getDistance(northWest, southEast);
      let keys = Object.keys(radiusZoomLevel).reverse();
      let value = 1000000;
      let index = 0;
      for (let i = 0; i < keys.length; i++) {
        let v = Math.abs(radiusZoomLevel[keys[i]] - d);
        if (v < value) {
          value = v;
          index = keys[i];
        }
      }
      mapInstance.setZoom(Number(index));
      setTimeout(() => {
        setLoadingPlan(false);
      }, 800);
    }
  };

  const getPci = () => {
    // get floorplan picture if it has
    api.get(`files/floorplans/${curFloor.id}`, {
      responseType: "arraybuffer"
    }).then(res => {
      let blob = new Blob([res.data], { type: "img/jpeg" });
      let url = (window.URL || window.webkitURL).createObjectURL(blob);
      setPicture(url);
    }).catch(() => setPicture(null));
  };

  useEffect(() => {
    // show floorplan mode if it has, or show map if it doesn't have floorplan
    if (mapApi && mapInstance && curFloor) {
      getPci();
      mapInstance.setOptions({ mapTypeControl: false, streetViewControl: false });
      markerArr.forEach(item => {
        if (item.type === "zones") {
          item.label.className = "marker-position-bottom bg-danger ps-1 pe-1 radius-3";
          item.label.color = "#FFFFFF";
        }
        if (item.type !== "zones" && item.type !== "zone") {
          item.label.className = "marker-position-bottom bg-primary ps-1 pe-1 radius-3";
          item.label.color = "#FFFFFF";
        }
      });
    } else if (mapApi && mapInstance && !curFloor) {
      setPicture(null);
      overlay1 && overlay1.setMap(null);
      overlay1 = null;
      mapInstance.setOptions({ mapTypeControl: true, streetViewControl: true });
      markerArr.forEach(item => {
        if(item.label) {
          item.label.className = "marker-position-bottom";
          item.label.color = "#000000";
        }
      });
    }
  }, [mapApi, mapInstance, curFloor]);

  useEffect(() => {
    // cell tower
    if (tower && mapInstance && mapApi) {
      tower1 && tower1.setMap(null);
      radiusCircle && radiusCircle.setMap(null);
      let latLng = new mapApi.LatLng(tower.lat, tower.lon);
      let markerIcon = {
        url: defaultZone,
        scaledSize: new mapApi.Size(30, 30),
        origin: new mapApi.Point(0, 0),
        anchor: new mapApi.Point(15, 15),
        labelOrigin: new mapApi.Point(20, -10)
      };

      tower1 = new mapApi.Marker({
        position: latLng,
        map: mapInstance,
        label: {
          text: "Cell Location",
          color: "#000000",
          fontSize: "16px",
          fontWeight: "bold"
        },
        icon: markerIcon
      });

      radiusCircle = new mapApi.Circle({
        strokeColor: "#090B29",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
        map: mapInstance,
        center: latLng,
        radius: tower.accuracy
      });

      mapInstance.panTo(latLng);
      mapInstance.setZoom(16);
    } else if (!tower && mapInstance && mapApi) {
      tower1 && tower1.setMap(null);
      radiusCircle && radiusCircle.setMap(null);
    }
  }, [mapApi, mapInstance, tower]);

  const getNearBy = async () => {
    // get nearby markers with a center point and radius
    if (!mapInstance.getCenter()) return;
    let count = [0, 0, 0];
    if (source) {
      source.cancel("request canceled");
    }
    source = axios.CancelToken.source();
    mapType = mapInstance.getMapTypeId();
    try {
      const res = await api.get(`maps/nearby?radius=${radiusZoomLevel[Math.round(zoomLevelRef.current)]}&latitude=${mapInstance.getCenter().lat()}&longitude=${mapInstance.getCenter().lng()}&limit=${limit}`, {
        cancelToken: source.token
      });
      const totalHeader = res.headers["x-total-count"];
      if (Number(totalHeader) >= limit) {
        setNearByData([]);
        setOverlay(true);
        count[0] = Number(totalHeader);
        markerArr && markerArr.forEach(item => {
          item.setMap(null);
          item = null;
        });
        markerArr = [];
        if (markerCluster) {
          markerCluster.clearMarkers();
        }
      } else {
        setNearByData(res.data);
        setOverlay(false);
        let arr = res.data;
        if (arr.length > 0) {
          let infoArr = infoItemRef.current.concat([])
          updateMarker(arr, markerArr, infoArr);
          let removeArr = resArr(markerArr, arr);
          removeMaker(removeArr, infoArr);
          let newArr = resArr(arr, markerArr);
          showMarker(newArr, infoArr);
          setInfoItem(infoArr);
        }
      }
      timer = null;
    } catch (e) {

    }
  };

  const removeMaker = (removeArr, infoArr) => {
    removeArr.forEach(item => {
      let index = infoArr.findIndex(f => f.lastPosition.id === item.id)
      if(index > -1) {
        infoArr[index].hide = true
      }
      for (let i = 0; i < markerArr.length; i++) {
        if (markerArr[i].idsArr.includes(item.id)) {
          if (markerArr[i].idsArr.length === 1) {
            markerArr[i].setMap && markerArr[i].setMap(null);
            markerCluster && markerCluster.removeMarker(markerArr[i]);
            markerArr.splice(i, 1);
            break;
          } else if (markerArr[i].idsArr.length > 1) {
            markerArr[i].idsArr = markerArr[i].idsArr.filter(arrItem => arrItem !== item.id);
            markerArr[i].ids = markerArr[i].ids.filter(arrItem => arrItem.id !== item.id);
            markerArr[i].id = markerArr[i].idsArr[0];
            markerArr[i].setMap && markerArr[i].setMap(mapInstance);
            if (markerArr[i].idsArr.length === 1) {
              markerArr[i].setIcon({
                ...markerArr[i].icon,
                scaledSize: new mapApi.Size(24, 24),
                url: imageSet[getUrl(markerArr[i].ids[0].icon)] ? require("../../../assets/img/icon/" + imageSet[getUrl(markerArr[i].ids[0].icon)]).default : defaultImg
              });
              markerArr[i].setLabel({
                className: "marker-position-bottom",
                color: "#000000",
                fontWeight: "bold",
                text: markerArr[i].ids[0].compoundName
              });
              mapApi.event.clearListeners(markerArr[i], "click");
              markerArr[i].addListener("click", function(e) {
                e.domEvent.stopImmediatePropagation();
                setCenter(markerArr[i].position);
                centerRef.current = markerArr[i].position;
                mapInstance.panTo(markerArr[i].position);
                if(mapInstance.getZoom() < 18) {
                  mapInstance.setZoom(18)
                }
                setInfoItem(pre => {
                  let obj = { lastPosition: this.ids[0] };
                  if (pre.length === 0) {
                    return [obj];
                  }
                  let arr = pre.concat([])
                  let filter = pre.filter(item => item?.lastPosition?.id !== obj.lastPosition.id);
                  if (filter.length === pre.length) {
                    arr.push(obj);
                    return arr;
                  } else {
                    return filter;
                  }
                });
              });
            }
            break;
          }
        }
      }
    });
  };

  const showMarker = (newArr, infoArr) => {
    newArr.forEach(item => {
      let filter = markerArr.filter(markerMe => markerMe.type !== "zone" && markerMe.type !== "zones" && markerMe.position.lat().toFixed(5) === Number(item.latitude).toFixed(5) && markerMe.position.lng().toFixed(5) === Number(item.longitude).toFixed(5));
      if (filter.length > 0 && item.type !== "zone" && item.type !== "zones") {
        if (item.type === "asset") filter[0].asset++;
        if (item.type === "person") filter[0].person++;
        filter[0].ids.push(item);
        filter[0].idsArr.push(item.id);
        filter[0].label.text = " ";
        filter[0].label.className = "font-bold";
        filter[0].icon.url = circlePng;
        filter[0].icon.scaledSize = new mapApi.Size(35, 35);
        if (hideOthers.current) {
          filter[0].setMap(null);
        } else {
          filter[0].setMap(mapInstance);
        }
        mapApi.event.clearListeners(filter[0], "click");
        filter[0].addListener("click", (e) => {
          e.domEvent.stopImmediatePropagation();
          setCenter(filter[0].position);
          centerRef.current = filter[0].position;
          setTableData(filter[0].ids);
        });
      } else if (item.type === "zone") {
        let m = new mapApi.Marker({
          id: item.id,
          type: "zones",
          asset: item.type === "asset" ? 1 : 0,
          person: item.type === "person" ? 1 : 0,
          zone: item.type === "zone" ? 1 : 0,
          ids: [item],
          idsArr: [item.id],
          position: { lat: Number(item.latitude), lng: Number(Number(item.longitude)) },
          label: {
            text: item.compoundName,
            color: curFloorRef.current ? "#FFFFFF" : "#000000",
            fontWeight: "bold",
            className: curFloorRef.current ? "marker-position-bottom bg-danger ps-1 pe-1 radius-3" : "marker-position-bottom"
          },
          icon: {
            url: imageSet[getUrl(item.icon)] ? require("../../../assets/img/icon/" + imageSet[getUrl(item.icon)]).default : defaultImg,
            anchor: new mapApi.Point(12, -0),
            scaledSize: new mapApi.Size(24, 24)
          },
          anchor: new mapApi.Point(14, 43),
          map: mapInstance
        });
        m.addListener("click", function(e) {
          e.domEvent.stopImmediatePropagation();
          setCenter(m.position);
          centerRef.current = m.position;
          mapInstance.panTo(m.position);
          let keys = Object.keys(radiusZoomLevel).reverse();
          let value = 1000000;
          let index = 0;
          for (let i = 0; i < keys.length; i++) {
            let v = Math.abs(radiusZoomLevel[keys[i]] - m.diam);
            if (v < value) {
              value = v;
              index = keys[i];
            }
          }
          mapInstance.setZoom(Number(index));
        });
        markerArr.push(m);
        if (item.radius) {
          m.diam = Number(item.radius) * 2;
          let c = new mapApi.Circle({
            id: item.id,
            idsArr: [item.id],
            ids: [item],
            strokeColor: "#090B29",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#090B29",
            fillOpacity: 0.4,
            shape: "circle",
            type: "zone",
            center: { lat: Number(item.latitude), lng: Number(Number(item.longitude)) },
            map: mapInstance,
            radius: Number(item.radius)
          });
          markerArr.push(c);
        } else if (item.polygon) {
          let firstItem = item.polygon[0].split(",");
          let minLat = { lat: Number(firstItem[1]), lng: Number(firstItem[0]) },
            maxLat = { lat: Number(firstItem[1]), lng: Number(firstItem[0]) },
            minLng = { lat: Number(firstItem[1]), lng: Number(firstItem[0]) },
            maxLng = { lat: Number(firstItem[1]), lng: Number(firstItem[0]) };
          let arr = item.polygon.map(item => {
            let me = item.split(",");
            let lng = Number(me[0]);
            let lat = Number(me[1]);
            if (lat > maxLat.lat) maxLat = { lat, lng };
            if (lat < minLat.lat) minLat = { lat, lng };
            if (lng > maxLng.lng) maxLng = { lat, lng };
            if (lng < minLng.lng) minLng = { lat, lng };
            return { lng, lat };
          });
          let point1 = new mapApi.LatLng(minLat);
          let point2 = new mapApi.LatLng(maxLat);
          let point3 = new mapApi.LatLng(minLng);
          let point4 = new mapApi.LatLng(maxLng);
          let d1 = getDistance(point1, point2);
          let d2 = getDistance(point3, point4);
          let d = d1 > d2 ? d1 : d2;
          m.diam = Number(d);
          let polygon = new mapApi.Polygon({
            paths: arr,
            idsArr: [item.id],
            id: item.id,
            ids: [item],
            type: "zone",
            shape: "polygon",
            strokeColor: "#000000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#242424",
            fillOpacity: 0.4,
            editable: false,
            map: mapInstance
          });
          markerArr.push(polygon);
        }
      } else {
        let markerFilter = markerArr && markerArr.filter(markerMe => markerMe?.type === "zones" && markerMe?.position.lat().toFixed(5) === Number(item?.latitude).toFixed(5) && markerMe?.position.lng().toFixed(5) === Number(item?.longitude).toFixed(5));
        let m = new mapApi.Marker({
          id: item.id,
          asset: item.type === "asset" ? 1 : 0,
          person: item.type === "person" ? 1 : 0,
          zone: item.type === "zone" ? 1 : 0,
          ids: [item],
          idsArr: [item.id],
          position: { lat: Number(item.latitude), lng: Number(Number(item.longitude)) },
          label: {
            text: item.compoundName,
            color: curFloorRef.current ? "#FFFFFF" : "#000000",
            fontWeight: "bold",
            className: curFloorRef.current ? "marker-position-bottom bg-primary ps-1 pe-1 radius-3" : "marker-position-bottom"
          },
          type: thingsArr[item.type],
          icon: {
            url: imageSet[getUrl(item.icon)] ? require("../../../assets/img/icon/" + imageSet[getUrl(item.icon)]).default : defaultImg,
            scaledSize: new mapApi.Size(24, 24),
            anchor: new mapApi.Point(12, 12),
            labelOrigin: new mapApi.Point(12, markerFilter && markerFilter.length > 0 ? -48 : 12)
          },
          anchor: new mapApi.Point(14, 43),
          map: hideOthers.current ? null : mapInstance
        });
        let filter = infoArr.filter(f => f.lastPosition.id === item.id)
        if(filter.length > 0) {
          filter[0].lastPosition.latitude = item.latitude
          filter[0].lastPosition.longitude = item.longitude
          filter[0].hide = false
        }
        m.addListener("click", function(e) {
          e.domEvent.stopImmediatePropagation();
          setCenter(this.position);
          setInfoItem(pre => {
            let obj = { lastPosition: this.ids[0] };
            if (pre.length === 0) {
              return [obj];
            }
            let arr = pre.concat([])
            let filter = pre.filter(item => item?.lastPosition?.id !== obj.lastPosition.id);
            if (filter.length === pre.length) {
              arr.push(obj);
              return arr;
            } else {
              return filter;
            }
          });
          centerRef.current = this.position;
          mapInstance.panTo(this.position);
          if(mapInstance.getZoom() < 18) {
            mapInstance.setZoom(18)
          }
        });
        markerArr.push(m);
        markerCluster && markerCluster.addMarker(m);
      }
    });
    if (hideOthers.current) {
      markerCluster && markerCluster.clearMarkers();
    }
    if (!markerCluster && !hideOthers.current && markerArr.filter(item => (item.type !== "zone" && item.type !== "zones")).length > 1) {
      showCluster();
    }
    window.markerArr = markerArr
  };

  const showCluster = () => {
    markerCluster = new MarkerClusterer({map: mapInstance, markers:markerArr.filter(item => (item.type !== "zone" && item.type !== "zones")), renderer: {
        render({position}) {
          return new mapApi.Marker({
            label: "",
            position,
            icon: {
              url: circle,
              scaledSize: new mapApi.Size(36, 36),
              anchor: new mapApi.Point(18, 18),
            },
          });
        }
      }, algorithm: new GridAlgorithm({
        gridSize: 7,
        maxZoom: 18,
      }), onClusterClick(e, cluster){
        mapInstance.setZoom(18)
        mapInstance.setCenter(cluster.marker.position)
        centerRef.current = cluster.marker.position
      }});
  };

  const apiHasLoaded = (map, maps) => {
    if (!map || !maps) return;
    setInstance(map);
    setApi(maps);
    maps.event.addListener(map, "zoom_changed", function() {
      if (!filterRef.current) {
        setZoomLevel(map.getZoom());
        zoomLevelRef.current = map.getZoom();
      } else {
        setTimeout(() => {
          setCenter(map.getCenter());
          setZoomLevel(map.getZoom());
          zoomLevelRef.current = map.getZoom();
          setFilter("");
          filterRef.current = "";
        }, 200);
      }
    });
    maps.event.addListener(map, "maptypeid_changed", function() {
      mapType = map.getMapTypeId();
    });
    map.addListener("dragend", () => {
      setCenter(map.getCenter());
      centerRef.current = map.getCenter();
      setZoomLevel(map.getZoom());
      zoomLevelRef.current = map.getZoom();
    });
    map.addListener("dragstart", () => {
      mapType = map.getMapTypeId();
      if (intervalNearby) {
        clearInterval(intervalNearby);
        intervalNearby = null;
      }
    });
  };

  const onClose = (e, item) => {
    e.stopPropagation();
    setInfoItem(pre => {
      let filter = pre.filter(i => i.lastPosition.id !== item.lastPosition.id);
      return filter;
    });
  };

  const addPlace = (s) => {
    mapType = mapInstance.getMapTypeId();
    setFilter(s);
    filterRef.current = s;
    mapInstance.setMapTypeId(mapType);
  };

  const onSelect = (data) => {
    if (data === null) {
      setLoadingPlan(false);
      setNoData(true);
    } else {
      hideAction && hideAction();
      setLoadingPlan(true);
    }
    setCurFloor(data);
    curFloorRef.current = data;
    setFirstLoad(true);
  };

  const clickTarget = (item) => {
    let arr = infoItem.concat([]);
    arr.forEach(i => {
      if (i.lastPosition.id === item.lastPosition.id) {
        i.active = true;
      } else {
        i.active = false;
      }
    });
    setInfoItem(arr);
  };

  const handleAll = () => {
    if(infoItemRef.current.length === 0) {
      let arr;
      if(hideOthers.current) {
        let obj = data;
        obj.lastPosition.compoundName = data.compoundName
        obj.lastPosition.reference = data.reference
        obj.lastPosition.id = data.id
        obj.lastPosition.type = window.location.pathname.indexOf('asset') > -1 ? 'asset' : 'person'
        arr = [obj]
        setInfoItem(() => {
          return arr
        })
        return
      } else {
        arr = markerArr.filter(item => (item.ids.length === 1 && item.type !== 'zones' && item.type !== 'zone' && item.map !== null))
      }
      let newArr = arr.map(item => {
        let obj = { lastPosition: item.ids[0] };
        return obj
      })
      setInfoItem(() => {
        return newArr
      })
    } else {
      setInfoItem([])
    }
  }

  return (
    <div>
      <div className="position-relative">
        {!noData && user?.showFloorPlans && <FloorplanSelector showDefault onSelect={onSelect} asset={id} />}
        {loadingPlan &&
        <div className="position-absolute w-100 h-100 bg-light z-50 d-flex align-items-center justify-content-center">
          <Spinner animation="border" />
        </div>}
        {overlay &&
        <div className="p-3 ps-2 bg-light text-warning">Too many items to show. Please zoom to narrow
          results</div>}
        <div style={{ height: 450, width: "100%" }} className={`position-relative ${curFloor ? "hidden-map" : ""}`}>
          {user?.role !== "Root" && mapApi && <div style={{top: (!noData &&  user?.showFloorPlans) ? '44px' : ''}} className={`position-absolute`}><NearByAssets result={nearByData} /></div>}
          {user?.role !== "Root" && mapApi && <div onClick={() => handleAll()} style={{top: (!noData &&  user?.showFloorPlans) ? '44px' : '', left: '30px'}} className={`position-absolute m-1 z-50 cursor-pointer`}><FontAwesomeIcon title={'All labels'} icon={faWindowMaximize}
                                                                                                                                                                    size={'lg'} color={'#293042'} className="bg-black"
                                                                                                                                                                    fixedWidth /></div>}
          <GoogleMapReact
            options={getMapOptions}
            bootstrapURLKeys={{
              key: globalConfig.googleMapKey,
              libraries: ["places", "geometry", "drawing", "visualization"]
            }}
            defaultCenter={[
              51.5288684709715,
              -0.10159865243033028
            ]}
            defaultZoom={0}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
          >
            {mapInstance && mapApi &&
            <SearchBox hide={curFloor} ref={searchBox} map={mapInstance} mapApi={mapApi}
                       addplace={(s) => addPlace(s)} />}
            {(user?.role === "Root" || hideOther) && mapInstance && mapApi && data?.lastPosition?.longitude && data?.lastPosition?.latitude &&
            <GeoMarker plan={curFloor} item={data} handleClick={() => {
              setInfoItem(pre => {
                let obj = data;
                obj.lastPosition.compoundName = data.compoundName
                obj.lastPosition.reference = data.reference
                obj.lastPosition.id = data.id
                obj.lastPosition.type = window.location.pathname.indexOf('asset') > -1 ? 'asset' : 'person'
                if (pre.length === 0) {
                  return [obj];
                }
                let filter = pre.filter(item => item?.lastPosition?.id !== obj.lastPosition.id);
                if (filter.length === pre.length) {
                  pre.push(obj);
                  return pre;
                } else {
                  return filter;
                }
              });
            }}
                       img={imageSet[getUrl(data.icon)] ? require("../../../assets/img/icon/" + imageSet[getUrl(data.icon)]).default : defaultImg}
                       lat={data.lastPosition.latitude} lng={data.lastPosition.longitude} />}
            {infoItem && infoItem.map(item => <InfoWindow key={item.lastPosition.id} clickTarget={clickTarget}
                                                          lat={item?.lastPosition.latitude}
                                                          lng={item?.lastPosition.longitude} show={item}
                                                          onClose={(e) => onClose(e, item)} />)}
          </GoogleMapReact>
        </div>
      </div>
      <Modal show={findAddress} onHide={() => {
        onCancelSearch();
        setAddress(null);
      }}>
        <Modal.Header closeButton><Modal.Title>Address Search</Modal.Title></Modal.Header>
        <Modal.Body>
          {address ? <p>{address}</p> : <p>Searching for address...</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            onCancelSearch();
            setAddress(null);
          }}>Cancel</Button>
        </Modal.Footer>
      </Modal>
      <MarkerModal tableData={tableData} setTableData={setTableData} />
    </div>
  );
});

const GeoMarker = ({ item, img, plan, handleClick }) => (
  <div style={{ marginLeft: "-12px", marginTop: "-12px" }} onClick={() => handleClick()}>
    <img
      alt={'marker'}
      style={{ height: "24", width: "24px", cursor: "pointer" }}
      src={img} />
    <div
      className={`position-absolute marker-position-bottom ps-1 pe-1 radius-3 text-nowrap ${plan ? "text-black bg-primary" : "marker-color-black"}`}
      style={{ fontSize: "14px", fontWeight: "bold", transform: "translate(-50%, 50%)" }}>{item?.compoundName}</div>
  </div>);


export default Markers;
